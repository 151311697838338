@import '../../styles/constant';

#login_form {
  max-width: 400px;
  margin: 0 auto;
  padding-top: 50px;
}

.login_header {
  max-width: 600px;
  margin: 0 auto;
  padding-top: 50px;
  text-align: center;
}

.error {
  padding-top: 10px;
  padding-left: 0px;
  display: flex;
  color: $color-main-red;
}
