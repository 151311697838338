@import '../../../../styles/constant.scss';

.current_page {
  font-size: 1.5rem;
  vertical-align: middle;
}

.country_name {
  font-size: 0.9rem;
}

.country_region {
  font-size: 0.7rem;
}

.current_page {
  line-height: 1;
}

/* Override some Bootstrap pagination styles */

.my_pagination {
  margin-top: 0;
  margin-bottom: 0;

  .my_page_item {
    display: inline-block;
    button.my_page_link.active {
      background-color: $color-main-heavy-color;
      color: #ffffff;
      font-weight: bold;
    }
    button.my_page_link {
      &.arrow_button:focus {
        outline: none;
      }
    }
  }

  button#jump_to {
    color: #ffffff;
    border-radius: 3px;
    background-color: $color-main-grey;
    font-weight: bold;
    padding: 0.25rem 0.75rem;
  }

  button.my_page_link {
    padding: 0.5rem 1rem;
    text-align: center;
    margin: 0 0.5rem;
    box-shadow: none !important;
    font-size: 1rem;
    border: none;
    background-color: transparent;

    &:hover {
      background-color: $color-main-heavy-color;
      color: #ffffff;
    }
  }

  #page_number {
    box-sizing: border-box;
    height: 32px;
    width: 54px;
    text-align: center;
    margin: 0 14px;
    border: 1px solid $color-main-grey;
    border-radius: 3px;
    background-color: #ffffff;
  }
  &.pagination_at_right {
    float: right;
    .simple_pagination_text {
      padding-top: 10px;
    }
    .simple_pagination_button {
      .my_page_link.arrow_button:focus {
        outline: none;
      }
    }
  }
}
