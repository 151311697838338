@import '../../styles/constant.scss';

@mixin aligned($direction) {
  float: $direction;
  text-align: $direction;
}

.money_row {
  direction: rtl;
  .flex_row {
    display: flex;
    flex-direction: row;
    .unit {
      color: $color-font-light-grey;
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  &.align_left {
    @include aligned(left);
  }
  &.align_right {
    @include aligned(right);
  }
}
