@import '../../styles/constant';
@import '../../styles/common';
@import '../../styles/button';

.paypal {
  text-align: left;
  &:hover {
    cursor: pointer;
  }
}

.block {
  margin-top: 20px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin-bottom: 15px;
  padding: 15px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
}
