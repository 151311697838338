@import '../../styles/common';
@import '../../styles/constant';

.modal_body {
  padding: 1rem 3rem;
}

.rule_group {
  background-color: #fff;
  border: 1px solid black;
  border-radius: 5px;
  .rule_item {
    border-bottom: 1px solid $color-border-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: $color-hover-blue;
      cursor: pointer;
      border-radius: 5px;
    }

    h5 {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    p {
      margin-bottom: 0;
    }

    .rule_item:last-child {
      border-bottom: 0px;
    }
  }
}

.label {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.label input {
  margin: 1rem;
}
