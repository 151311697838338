@import './button';
@import './form';
@import './constant';

$primary-color: #0d73ea;

img {
  max-width: 100%;
  max-height: 100%;
}

.App-header {
  text-align: center;
}
.navbar {
  .navbar-brand img {
    height: 35px;
  }
}
#body-container {
  width: 100%;
}
.container-with-padding {
  padding: 1rem;
}
.alert {
  max-width: 20rem;
  margin: 25% auto;
}
#main-container {
  font-family: 'Roboto';
  font-size: 16px;
}

.lds-ring {
  div {
    border: 4px solid $primary-color !important;
    border-color: $primary-color transparent transparent transparent !important;
  }
}

.form-control::placeholder,
.input::placeholder {
  opacity: 0.4;
}
