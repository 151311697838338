@import '../../styles/common';
.hide_modal_body {
  @include hidden_input;
}
.hide_modal_content {
  border: hidden;
  background-color: transparent;
  width: inherit;
}
.full_screen_modal {
  border: 0;
  height: 100vh;
  width: 100vw;
  padding: 0;
  position: absolute;
}
