.epsubs_top_nav_bar {
  font-size: 16px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  z-index: 10;
  a.nav-link,
  a {
    color: rgba(0, 0, 0, 0.5);
  }
  a.nav-link.active,
  a {
    &.active {
      color: rgba(0, 0, 0, 0.9);
      font-weight: bold;
    }
  }
  .dropdown-item {
    &:active {
      background-color: lightgrey;
    }
  }
}

.dropdown {
  padding-left: 8px;
  padding-right: 8px;
}

.top_bar {
  &:hover {
    text-decoration: underline;
  }
}

.top_bar_no_dropdown {
  &:hover {
    color: black;
    text-decoration: underline;
  }
}
