@import './constant';

.btn.btn-primary {
  background-color: $color-main-light-blue;
  border-color: $color-main-light-blue;
  color: white;
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: $color-main-dim-blue;
    border-color: $color-main-dim-blue;
  }
}

.btn.btn-secondary {
  background-color: $color-main-grey;
  border-color: $color-main-grey;
  color: white;
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: $color-main-light-grey;
    border-color: $color-main-light-grey;
  }
}

.btn.btn-dark {
  background-color: $color-main-heavy-blue;
  border-color: $color-main-heavy-blue;
  color: white;
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: $color-main-dim-blue;
    border-color: $color-main-dim-blue;
  }
}

.btn.btn-light {
  background-color: $color-bg-heavy-grey;
  border-color: $color-bg-heavy-grey;
  color: $color-main-blue;
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: $color-bg-light-grey;
    border-color: $color-bg-light-grey;
  }
}

.dropdown {
  .btn-blank {
    &,
    &:focus,
    &:hover {
      color: white;
      outline: none;
      box-shadow: none;
    }
  }
}

@mixin action_text {
  color: $color-main-blue;
  cursor: pointer;
}

a.action_text {
  @include action_text();
}

@mixin form_button {
  margin-right: 17px;
  font-size: 20px;
  font-weight: bold;
}
