@import '../../../styles/constant.scss';
@import '../../../styles/common.scss';

.filter_card {
  background-color: $color-bg-light-blue;
  border: 1px solid $color-main-dim-blue;
  margin: 1rem 3rem 0rem 3rem;
}

.square_button {
  @include solid_boundary;
  @include square_shape(2.5rem);
}

.filter_dropdown {
  @include solid_boundary;
  min-width: 20rem;
}

.simple_filter_dropdown {
  @include solid_boundary;
  min-width: 20rem;
}

.multi_simple_filter_dropdown {
  @include solid_boundary;
  min-width: 20rem;
  margin-right: 1rem;
}

.multi_select_filter_dropdown {
  @include solid_boundary;
  min-width: 20rem;
}
