@import '../../styles/constant';
@import '../../styles/common';
@import '../../styles/button';

.form {
  max-width: 720px;
}

h5 {
  margin-bottom: 27px;
  font-size: 20px;
}

.form_row_label_text {
  padding-top: 6px;
  font-weight: bold;
  font-size: 18px;
}

.form_row_label_star {
  padding-top: 6px;
  font-weight: bold;
  font-size: 18px;
  color: red;
  margin-left: 5px;
}

.error {
  padding-left: 5px;
  display: flex;
  color: $color-main-red;
}

.form_button {
  @include form_button;
}

.form_checkbox {
  @include form_checkbox;
}
