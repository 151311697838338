@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./styles/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Medium';
  src: local('Roboto'), url(./styles/fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Thin';
  src: local('Roboto'), url(./styles/fonts/Roboto-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Bold';
  src: local('Roboto'), url(./styles/fonts/Roboto-Bold.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pac-container {
  z-index: 1051 !important;
}

.col p {
  word-wrap: anywhere;
}