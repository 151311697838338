@import '../../styles/button';
@import '../../styles/common';
@import '../../styles/constant';

.action_text {
  @include action_text;
  direction: rtl;
}

.address_indicator {
  color: red;
  margin-left: 10px;
}

.name {
  font-weight: bold;
  font-size: 14px;
}

.subTitle {
  color: #737373;
}

.card {
  background-color: rgba(97, 95, 95, 0.1);
  padding: 30px;
}

.action_text {
  color: $color-main-blue;
  cursor: pointer;
}
.num_indicator {
  color: $color-main-red;
  margin-left: 2rem;
}
.error {
  display: flex;
  color: $color-main-red;
}
.success {
  display: flex;
  color: $color-main-green;
}

.form_checkbox {
  @include hidden_input;
}

.duplication_text {
  margin-top: 8px;
  color: $color-main-red;
  font-size: 13px;
  cursor: pointer;
}

.name_text {
  margin-top: 9px;
  margin-left: 9px;
  color: $color-main-blue;
  cursor: pointer;
}

.validation_status {
  &:focus {
    p.validation_text_container {
      border: solid 2px $color-main-blue;
      border-radius: 6px;
      padding: 0px 0.5rem;
      background-color: yellow;
    }
  }
}

img.validation {
  vertical-align: text-top;
  margin-top: 1px;
}

.edit_text {
  &:hover {
    text-decoration: underline;
  }
}

.form_row_label_text {
  padding-top: 6px;
  font-weight: bold;
  font-size: 18px;
}

.form_row_label_star {
  padding-top: 6px;
  font-weight: bold;
  font-size: 18px;
  color: red;
  margin-left: 5px;
}
