@import '../../styles/constant';

.card_form {
  max-width: 600px;
  margin: 0 auto;
  padding-top: 20px;
}

.stripe_fields,
.hosted_field {
  height: 50px;
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  display: inline-block;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid #ddd;
  line-height: 20px;
  background: #fcfcfc;
  margin-bottom: 12px;
  background: linear-gradient(90deg, #fff 50%, #fcfcfc 0);
  background-position-x: 0;
  background-position-y: 0;
  background-size: 200% 100%;
  background-position: 100% 100%;
  transition: all 0.3s ease-in-out;
}

.form_fields {
  font-family: sans-serif;
  font-weight: 500;
  padding: 0px 12px;
  font-size: 20px;
  color: #424770;
  width: 101%;
  height: 49px;
  &:focus {
    color: #424770;
    background-color: none;
    border-color: #d3d8dc;
    box-shadow: none;
  }
}
