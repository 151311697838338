@import '../../styles/button';

h5 {
  margin-bottom: 27px;
  font-size: 20px;
}

.error {
  padding-left: 20px;
  display: flex;
  color: $color-main-red;
}

.form_button {
  @include form_button;
}
