@import '../../styles/constant';

.form_row_label_text {
  font-weight: bold;
  font-size: 18px;
  padding-top: 5px;
}

.form_row_label_star {
  font-weight: bold;
  font-size: 18px;
  padding-top: 5px;
  color: red;
  margin-left: 5px;
}

.error {
  font-size: 12px;
  margin-bottom: 0px;
  padding-top: 5px;
  display: flex;
  color: $color-main-red;
}

.form_date_picker {
  padding: 0.1rem 0.5rem;
  border: 1px solid $color-main-light-grey;
  width: 100%;
  display: block;
  input {
    width: 100%;
    border: none;
  }
}

.flex_row_calendar {
  display: flex !important;
}
