@import '../../styles/constant';

.action_text {
  padding: 0 0.1rem;
  color: $color-main-blue;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: $color-main-dark-blue;
  }
}
