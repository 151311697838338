@import '../../styles/constant';

@mixin flag_common {
  font-size: 13px;
  margin-left: 6px;
  margin-right: 6px;
  padding-left: 6px;
  padding-right: 6px;
  min-width: 83px;
  height: 22px;
  border-bottom-color: white;
  text-align: center;
  font-weight: bold;
}

@mixin flag_solid($color) {
  @include flag_common;
  // Color
  border-radius: 3px;
  color: white;
  background-color: $color;
  border: 1px solid $color;
}

@mixin flag_blank($color) {
  @include flag_common;
  // Color
  border-radius: 3px;
  color: $color;
  background-color: white;
  border: 1px solid $color;
}

.flag_solid {
  &.flag_red {
    @include flag_solid($color-main-red);
  }
  &.flag_green {
    @include flag_solid($color-main-green);
  }
  &.flag_blue {
    @include flag_solid($color-main-heavy-blue);
  }
  &.flag_light_blue {
    @include flag_solid($color-bg-light-blue);
    color: black;
  }
  &.flag_grey {
    @include flag_solid($color-font-grey);
  }
  &.flag_light_grey {
    @include flag_solid($color-bg-grey);
    color: black;
  }
  &.flag_orange {
    @include flag_solid($color-main-orange);
  }
  &.flag_black {
    @include flag_solid($color-border-light-grey);
  }
  &.flag_golden_rod {
    @include flag_solid(#ccb06d);
  }
  &.flag_barn_red {
    @include flag_solid(#9b2e3d);
  }
  &.flag_purple {
    @include flag_solid(#8d5cad);
  }
  &.flag_bright_cyan {
    @include flag_solid(#17a2b8);
  }
  &.flag_yellow {
    @include flag_solid(#f9e076);
  }
}

.flag_blank {
  &.flag_red {
    @include flag_blank($color-main-red);
  }
  &.flag_green {
    @include flag_blank($color-main-green);
  }
  &.flag_blue {
    @include flag_blank($color-main-heavy-blue);
  }
  &.flag_light_blue {
    @include flag_blank($color-bg-light-blue);
    color: black;
  }
  &.flag_grey {
    @include flag_blank($color-font-grey);
  }
  &.flag_light_grey {
    @include flag_blank($color-bg-grey);
    color: black;
  }
  &.flag_orange {
    @include flag_blank($color-main-orange);
  }
  &.flag_black {
    @include flag_blank($color-border-light-grey);
  }
  &.flag_golden_rod {
    @include flag_blank(#ccb06d);
  }
  &.flag_barn_red {
    @include flag_blank(#9b2e3d);
  }
  &.flag_purple {
    @include flag_blank(#8d5cad);
  }
}
