$font-main-content: 'Maven Pro', sans-serif;
$font-main-title: 'Roboto', sans-serif;
$font-main-mono: 'Roboto Mono', monospace;

$color-font-dark: rgb(33, 33, 33);
$color-font-heavy-grey: rgb(74, 74, 74);
$color-font-grey: #737373;
$color-font-light-grey: #9c9c9c;

$color-bg-light-light-grey: #fbfbfb;
$color-bg-light-grey: #f5f5f5;
$color-bg-grey: #e5e5e5;
$color-bg-heavy-grey: #dddddd;

$color-bg-subnav: #efeeee;

$color-border-light-grey: #d8d8d8;
$color-border-grey: #cdcdcd;
$color-border-heavy-grey: #b2b2b2;

$color-main-dark: rgb(50, 50, 50);
$color-main-light-grey: #d3dae8;
$color-main-grey: #979797;
$color-main-heavy-grey: #575757;
$color-main-grey-alpha: 0.1;
// style for front colors
$color-main-red: #e73650;
$color-main-red-alpha: 0.8;
$color-main-red-light-alpha: 0.2;
$color-main-purple: rgb(169, 100, 219);
$color-main-dark-blue: rgb(0, 91, 161);
// style for admin front
$color-main-blue: #0093ff;
$color-main-dim-blue: #78befa;
$color-main-orange: #e9a700;
$color-main-heavy-blue: #2a5ec7;
$color-bg-heavy-blue: #1b2b55;
$color-main-light-blue: #4a90e2;
$color-main-blue-alpha: 0.1;
$color-bg-blue: #d7f0fa;
$color-bg-light-blue: #ebf4ff;
$color-hover-blue: #ddeffe;

$color-primary: #3ea4b1;

$color-main-green: #7cba41;
$color-main-heavy-green: #24ac93;
$color-main-light-green: #fafcfd;
$color-main-green-alpha: 0.1;

$color-main-color: $color-main-dim-blue;
$color-main-heavy-color: $color-main-heavy-blue;
$color-main-light: $color-main-light-blue;
$color-main-alpha: $color-main-blue-alpha;

// style for data validation error
$color-error: #f44336;

$mobileTopBar-height: 44px;

$device-mobileS: 320px;
$device-mobileM: 375px;
$device-mobileL: 425px;
$device-tablet: 768px;
$device-laptop: 1024px;
$device-laptopL: 1440px;
$device-desktop: 2560px;
