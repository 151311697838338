@import '../../styles/constant';
@import '../../styles/common';
@import '../../styles/button';

.email {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}
.name {
  text-align: center;
  font-size: 20px;
}

.circle {
  line-height: 100px;
  border-radius: 50%;
  width: 40%;
  margin: auto;
  font-size: 50px;
  color: #fff;
  text-align: center;
  background: #4a90e2;
}
