@mixin epsubs_table {
  thead {
    tr {
      // Font
      font-weight: bold;
      color: $color-main-grey;
      background-color: white;
    }
  }
  tbody {
    // Boundary
    tr {
      td {
        border-top-width: 1px;
        background-color: white;
      }
    }
  }
}

@mixin addendum_row {
  border-bottom: 1px solid $color-border-light-grey;
  padding-bottom: 15px;
  margin: 15px 0 0 0;

  p {
    word-wrap: break-word;
  }

  &:last-child {
    border-bottom: none;
  }
}

@mixin divider {
  margin-bottom: 1rem;
  border-bottom: 1px solid $color-main-light-grey;
}

@mixin subheading {
  @include divider;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  h4 {
    line-height: 46px;
    font-weight: bold;
    font-size: 20px;
  }
  .action_text {
    @include action_text();
  }
}

@mixin action_text {
  color: $color-main-blue;
  cursor: pointer;
}

@mixin form_checkbox($size: 1rem) {
  height: $size;
  width: $size;
}

@mixin hidden_input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
}

@mixin solid_boundary {
  border: 1px solid black;
  border-radius: 0.25rem;
}

@mixin square_shape($size) {
  width: $size;
  height: $size;
}
