@import '../../styles/constant';

.update-payment-container {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-bottom: 100px;
  justify-content: center;
  align-items: center;
}

.update-payment-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: thin;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 50px 0;
  background: #fff;
  @media (min-width: $device-mobileL) {
    width: 500px;
  }
  @media (min-width: $device-laptop) {
    width: 750px;
  }
}

.update-payment-body-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.update-payment-body-header-text {
  margin-top: 50px;
  font-family: inherit;
  font-weight: 800;
  color: $color-main-blue;
}

.update-payment-body-warning-banner {
  width: 100%;
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  padding: 20px 100px;
  background-color: $color-main-light-blue;
}
.update-payment-body-list-ul {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: -moz-calc(100% - 20px);
  width: -webkit-calc(100% - 20px);
  width: calc(100% - 20px);
  margin: 50px 0 50px 0;
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
}

.update-payment-add-new-btn {
  font-family: inherit;
  font-weight: 800;
  font-size: 14px;
  padding: 15px 60px;
  color: $color-main-light-blue;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: none;
}

.link_to:hover {
  text-decoration: underline;
}
