@import '../../../../styles/constant.scss';
@import '../../../../styles/common.scss';

.select_value {
  height: 2rem;
}

@mixin square_button {
  @include solid_boundary;
  @include square_shape(2.5rem);
}
.minus_button {
  @include square_button;
  background-color: $color-bg-heavy-blue;
  p {
    font-weight: bold;
    color: white;
    font-size: large;
  }
}

.plus_button {
  @include square_button;
  background-color: white;
  p {
    font-weight: bold;
  }
}

.filter_dropdown {
  @include solid_boundary;
  padding: 0;
  margin: 8px;
}

.filter_form {
  padding: 0;
  margin: 8px;
}

.filter_key_dropdown {
  @include solid_boundary;
  padding: 0;
}

.multi_select_dropdown {
  @include solid_boundary;
  padding: 0;
  margin-top: 8px;
}

.text_input {
  @include solid_boundary;
  padding: 0;
  margin-top: 8px;
}

.number_input {
  @include solid_boundary;
  padding: 0;
  margin-top: 8px;
}

.calendar {
  @include solid_boundary;
  padding: 0;
  max-width: 14rem;
  max-height: 2.5rem;
  margin-top: 8px;
  background-color: white;
}

.operation_button {
  color: $color-main-blue;
  border: 1px solid $color-main-light-blue;
  &:hover {
    border: 1px solid $color-main-dim-blue;
  }
}
