.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: rgb(50, 50, 50);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 5px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: 40px;
  right: -30%;
  // margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
