@import '../../../styles/constant.scss';

.thead_label {
  cursor: pointer;
}

.flex_row {
  display: flex;
  flex-direction: row;
  .icon_text {
    padding-left: 10px;
  }
}

.bg_grey {
  background-color: $color-bg-grey;
}
